'use strict';
import StartPage from "./start-page";

import btnEnterImg from '../images/btn_enter.png';
import textRegionImg from '../images/text_region.png';
import btnRightImg from '../images/btn_right.png';

import systemNameIndexImg0 from '../images/system_name_index/0.png';
import systemNameIndexImg1 from '../images/system_name_index/1.png';
import systemNameIndexImg2 from '../images/system_name_index/2.png';
import systemNameIndexImg3 from '../images/system_name_index/3.png';
import systemNameIndexImg4 from '../images/system_name_index/4.png';
import systemNameIndexImg5 from '../images/system_name_index/5.png';
import systemNameIndexImg6 from '../images/system_name_index/6.png';
import systemNameIndexImg7 from '../images/system_name_index/7.png';
import systemNameIndexImg8 from '../images/system_name_index/8.png';
import systemNameIndexImg9 from '../images/system_name_index/9.png';
import systemNameIndexImg10 from '../images/system_name_index/10.png';
import systemNameIndexImg11 from '../images/system_name_index/11.png';

import textChanglangImg from '../images/text_changlang.png';

import Utils from "./utils";

var SceneEnter = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneEnter ()
  {
    Phaser.Scene.call(this, { key: 'sceneEnter', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.allElements = [];    

    this.startPage = new StartPage(this, this.scaler);

    this.sceneBoot.currentScene = 'sceneEnter';

    this.isEntered = false;

    window.x = this;
  },

  preload: function ()
  {
    this.load.image('btn_enter', btnEnterImg);
    this.load.image('text_region', textRegionImg);
    this.load.image('btn_right', btnRightImg);

    if(this.params && this.params.personal){
      this.load.image(this.params.personal.texture, this.params.personal.path);
    }

    var systemNameIndexImgs = [systemNameIndexImg0, systemNameIndexImg1, systemNameIndexImg2, systemNameIndexImg3, systemNameIndexImg4, systemNameIndexImg5,
           systemNameIndexImg6, systemNameIndexImg7, systemNameIndexImg8, systemNameIndexImg9, systemNameIndexImg10, systemNameIndexImg11];

    this.textures.removeKey('system_name_index');
    if(this.params && this.params.system){
      this.load.image('system_name_index', systemNameIndexImgs[this.params.system.id]);
    }
    this.load.image('text_changlang', textChanglangImg);

    this.startPage.preload();
  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    this.startPage.create();

    var children = this.startPage.group.getChildren();
    for(var i = 0; i < children.length; i ++){
      this.allElements.push(children[i]);
    }

    this.enterGroup = this.add.group();

    designX = 0;
    designY = 614;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'btn_enter');
    sprite.setOrigin(0.5, 0.5);
    sprite.alpha = 0;
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    this.btnEnterSprite = sprite;
    this.allElements.push(sprite);
    
    if(this.params && this.params.personal){//个人展位
      designX = 1308;
      designY = 591;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        this.params.personal.texture);
      sprite.setOrigin(0, 0);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.nameSprite = sprite;
      this.allElements.push(sprite);

      designX = 1694;
      designY = 622;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'text_region');
      sprite.setOrigin(0.5, 0.5);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.textRegionSprite = sprite;
      this.allElements.push(sprite);

      designX = 1850.5;
      designY = 625.5;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'btn_right');
      sprite.setOrigin(0.5, 0.5);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.btnRightSprite = sprite;
      this.allElements.push(sprite);
    }

    if(this.params && this.params.system){
      designX = 1308;
      designY = 591;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'system_name_index');
      sprite.setOrigin(0, 0);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.systemSprite = sprite;
      this.allElements.push(sprite);

      designX = 1694;
      designY = 622;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'text_changlang');
      sprite.setOrigin(0.5, 0.5);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.textChanglangSprite = sprite;
      this.allElements.push(sprite);

      designX = 1850.5;
      designY = 625.5;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'btn_right');
      sprite.setOrigin(0.5, 0.5);
      sprite.alpha = 0;
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      sprite.visible = false;
      this.btnRightSprite = sprite;
      this.allElements.push(sprite);
    }

    if(this.params && this.params.personal){
      //个人展位
      this.enterGroup.add(this.nameSprite);
      this.enterGroup.add(this.textRegionSprite);
      this.enterGroup.add(this.btnRightSprite);
    } else if(this.params && this.params.system){
      this.enterGroup.add(this.systemSprite);
      this.enterGroup.add(this.textChanglangSprite);
      this.enterGroup.add(this.btnRightSprite);
    }else{
      //正常模式
      this.enterGroup.add(this.btnEnterSprite);

      this.startPage.getCookie();
    }


    this.input.on('pointerup', function () {
      if(this.btnEnterSprite){
        this.enterGroup.setAlpha(1);
      }
    }, this);

    this.sceneBoot.windowResized = true;
  },

  update: function(){
    this.startPage.update();

    // if(this.scaler.width >= this.scaler.height && this.startPage.videoIsReady == true && this.btnEnterSprite.visible == false){
    //   this.btnEnterSprite.visible = true;
    // }
  },

  resized: function () {
    if(this.scaler.width >= this.scaler.height){
      //横屏
      this.enterGroup.setVisible(true);

      var phaserElements = this.children.list;
      for(var i = 0; i < phaserElements.length; i ++){
        var s = phaserElements[i];
        if(s.keepData && s.keepData.resized == true){
          if(s.type == 'Video'){
            s.x = this.scaler.scaleX(this.scaler.designRefWidth()/2);
            s.y = this.scaler.scaleY(this.scaler.designRefHeight()/2);
            this.scaler.scaleSpriteByMax(s);
          } else{
            if(s.keepData.xlocation && s.keepData.xlocation == 'center'){
              s.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + s.keepData.designX);
            } else{
              s.x = this.scaler.scaleX(s.keepData.designX);
            }

            if(s.keepData.ylocation && s.keepData.ylocation == 'bottom'){
              s.y = this.scaler.scaleY(this.scaler.designRefHeight() + s.keepData.designY);
            } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
              s.y = this.scaler.scaleY(this.scaler.designRefHeight()/2 + s.keepData.designY);
            } else{
              s.y = this.scaler.scaleY(s.keepData.designY);
            }
            
            this.scaler.scaleSprite(s);
          }
        }
      }

      if(this.isEntered == false){
        this.isEntered = true;
        this.elementsEnter();
      }
    } else{
      this.enterGroup.setVisible(false);
    }

    this.startPage.resized();
  },

  enterAnimation: function(func_callback){
    var children = this.enterGroup.getChildren();
    var delay = 1000;
    var completeNum = 0;
    for(var  i = 0; i < children.length; i ++){
      children[i].visible = true;
      children[i].alpha = 0;
      this.tweens.add({
        targets: children[i],
        alpha: 1,
        duration: 500,
        delay: delay,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          completeNum ++;

          if(completeNum >= children.length){
            if(func_callback){
              func_callback();
            }
          }
        },
      });
    }

    
    
  },

  enterAnimationOld: function(func_callback){
    var delay = 1000;
    this.enterTextSprite.x = this.scaler.scaleX(this.enterTextSprite.keepData.designX + 30);
    this.tweens.add({
      targets: this.enterTextSprite,
      x: this.scaler.scaleX(this.enterTextSprite.keepData.designX),
      y: this.scaler.scaleY(this.enterTextSprite.keepData.designY),
      alpha: 1,
      duration: 1000,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
        if(func_callback){
          func_callback();
        }
      },
    });

    this.enterTriangleSprite.y = this.scaler.scaleY(this.enterTriangleSprite.keepData.designY - 30);
    this.tweens.add({
      targets: this.enterTriangleSprite,
      x: this.scaler.scaleX(this.enterTriangleSprite.keepData.designX),
      y: this.scaler.scaleY(this.enterTriangleSprite.keepData.designY),
      alpha: 1,
      duration: 1000,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },

  outAnimation: function(func_callback){
    var self = this;
    var completeNum = 0;
    for(var i = 0; i < this.allElements.length; i ++){
      var s = this.allElements[i];
      this.tweens.add({
        targets: s,
        x: s.x - this.scaler.width,
        alpha: 1,
        duration: 0,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].keepData.resized = false;
          this.targets[0].visible = false;
          completeNum ++;
          if(completeNum >= self.allElements.length){
            if(func_callback){
              func_callback();
            }
          }
        },
      });
    }
  },

  clickEnterButton: function(){
    if(this.params && this.params.personal){//个人展位
      var sceneGuide = this.scene.get('sceneGuide');
      sceneGuide.latest = this.params.latest;

      this.scene.start('sceneGuide', {autoJump: true, type: 'region', noTip: false});
    } else if(this.params && this.params.system){

      this.scene.start('sceneGuide', {autoJump: true, type: 'category', noTip: false});
    } else{
      this.startPage.playVideo();
      this.outAnimation();
    }
    
  },

  elementsEnter: function(){
    var self = this;
    this.startPage.enterAnimation();

    this.enterAnimation(function(){
      self.sceneBoot.windowResized = true;

      var children = self.enterGroup.getChildren();
      for(var  i = 0; i < children.length; i ++){
        children[i].setInteractive();

        children[i].on("pointerdown", pointer => {
          self.enterGroup.setAlpha(0.5);
        });

        children[i].on("pointerup", pointer => {
          self.enterGroup.setAlpha(1.0);

          self.clickEnterButton();
        });
      }
    });
  },
  
});
export default SceneEnter;