import '../styles/index.scss';

"use strict";

// import viewer from "./viewer";
import SceneBoot from './scene-boot';
import SceneEnter from './scene-enter';
import SceneGuide from './scene-guide';
import SceneMain from './scene-main';
import Utils from './utils';

window.utils = new Utils();

// window.vconsole = new VConsole();

window.custom = {
  systemList: {},
  selected: {id: 0, name: '',category: '本科生'},
  locator: ''
};

window.videoCannotPlay = false;

var config = {
  type: Phaser.CANVAS,
  width: 800,
  height: 800,
  scale: {
    mode: Phaser.DOM.RESIZE
    // mode: Phaser.Scale.FIT,
    // autoCenter: Phaser.Scale.CENTER_BOTH
  },
  transparent: true,
  parent: 'phaser-wrapper',
  scene: [SceneBoot, SceneEnter, SceneMain, SceneGuide]
};

var game = new Phaser.Game(config);

utils.fetchRegions();

Zepto(function($) {
  window.needOffset = utils.isIphoneXORIphone11Wechat();
  window.videoCannotPlay = utils.getCookie('videoCannotPlay') == 'true' ? true: false;

  utils.wechatShare();

    
  window.onbeforeunload = ()=>{
    utils.delCookie('selected');
    utils.delCookie('locator');
  };

  window.addEventListener('pagehide',()=>{
    // if(utils.isWechat()){
      utils.delCookie('selected');
      utils.delCookie('locator');
    // }
  });

});
